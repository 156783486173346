<template>
  <v-app id="noticePrivacy">
    <v-main>
      <v-container fluid fill-height>
        <v-row align="center" justify="center">
          <v-col cols="12" >
            <v-img :src="logo" max-height="90" contain/> 
          </v-col>
          <v-col cols="6">
            <v-card class="mx-auto" elevation="5" max-width="500"  max-height="500" style=" overflow: scroll">
              <v-card-title class="justify-center mb-3 main-title">
                Aviso de privacidad
              </v-card-title>
              <v-card-text>
                <div>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit dapibus, non mi accumsan eget 
                  cursus posuere aptent, fusce euismod dictum suspendisse neque sem duis. Tincidunt dignissim 
                  vivamus venenatis nisl tempor eros sollicitudin torquent varius tortor at aenean, ac quis 
                  posuere aptent sagittis orci molestie malesuada pulvinar velit justo, mi primis nunc arcu 
                  fames pharetra eleifend metus placerat sodales habitasse. Rhoncus egestas eget quam nisl 
                  magnis mollis, porta gravida nec orci mauris imperdiet tempus, ad felis proin nisi sagittis.
                  Parturient mauris nunc viverra litora penatibus odio diam enim faucibus pellentesque, nulla 
                  leo habitasse suspendisse bibendum porta justo praesent libero, urna turpis laoreet proin 
                  per placerat nisl phasellus blandit. Nunc gravida semper aptent curae diam porttitor dapibus 
                  posuere vel tempor, turpis mus neque metus lacus ultrices etiam ultricies hendrerit rutrum, 
                  nam placerat sed euismod phasellus congue per bibendum mollis. Netus ac parturient leo
                  tristique erat nostra pharetra, dapibus convallis curae rhoncus ridiculus in arcu, ornare 
                  urna odio non velit lobortis. Elementum rutrum aptent facilisi est sodales gravida blandit
                  praesent feugiat lectus, conubia primis a penatibus pellentesque enim rhoncus sagittis fusce
                  magnis, aliquam laoreet augue senectus scelerisque facilisis cubilia purus ornare. 
                  Vulputate curabitur penatibus nam nisi habitant ligula diam, cursus viverra ac euismod
                  fames leo eros vestibulum, vel pretium augue praesent sed et. Mattis sociosqu augue enim 
                  id nostra tempor sodales egestas, hendrerit nisi sociis praesent dui rutrum nunc, aptent 
                  duis primis neque quisque nisl eget.
                </div>
              </v-card-text>
            </v-card>
            <v-btn style="display: flex; margin: auto" class="mt-10" large rounded color="white" :disabled="loading" @click="back()">Volver</v-btn>
          </v-col>
        </v-row>
        <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
          {{ msgError }}
        </v-snackbar>
        <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
          {{ msgSuccess }}
        </v-snackbar>
      </v-container>
    </v-main>
    <main-footer/>
  </v-app>
</template>

<script>
import mainFooter from '@/components/Footer'

export default {
  name: 'noticePrivacy',
  components: { mainFooter },
  data: () => ({
  }),
  mounted() {
  },
  computed: {
  },
  methods: {
    back() {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
  .vl {
    border-left: 1px solid rgb(255, 255, 255);
    height: 300px;
  }
  .main-title {
    font-size: 28px;
    color: #5271ff;
  }
</style>
